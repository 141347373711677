import React, {useEffect, useState} from "react";
import "../../dropdown/dropdown.scss";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select, {components} from "react-select";
import {useTranslation} from "react-i18next";
import Toaster from "../../../util/toaster/Toaster";
import Api from "../../../util/api/Api";
import {GlobalPageMethods} from "../../page/Page";
import {Tooltip} from "../FormField";
import AIicon from "../../../resources/icons/AI-icon.svg";
import OwnInputIcon from "../../../resources/icons/own-input-icon.svg";
import {PrefixRadioButtons} from "../../createpersonform/CreatePersonForm";
import SURFButton from "../../../styled-components/buttons/SURFButton";
import {majorelle, majorelleLight, spaceCadetLight, SURFShapeRight} from "../../../Mixins";
import {HelperFunctions} from "../../../util/HelperFunctions";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const TopFlexContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const VocabularyOptionField = styled(Select)`
    width: 100%;
    height: auto !important;
`

const Flex = styled.div`
    align-items: center;
    height: auto;
    width: 100%;
    display: flex;
`

const Image = styled.img`
    height: 50px;
    color: #906AF1;
`

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
    margin: 0;
`

const Buttons = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`

function VocabularyPopupContent2(props){
    const {t} = useTranslation()

    let label = t('language.current_code') === 'nl' ? 'labelNL' : 'labelEN';

    return (
        <div className={"add-publication-popup-content-wrapper"}>
            <PopupContent {...props} />
        </div>
    )

    function PopupContent(props){
        const [options, setOptions] = useState([])
        const [selectedVocabulary, setSelectedVocabulary] = useState(null)
        const [selectedMethod, setSelectedMethod] = useState('method-diy')

        const resolveTitle = (jsonKey: string | null | undefined, label: string): string => {
            const title = jsonKey?.includes("vocabulary")
                ? t("vocabulary_field.popup.title")
                : label;

            return HelperFunctions.capitalize(title);
        };

        const CustomSelectElement = (props2) => {
            const isDisabled = props2.data.disabled;

            return (
                <div
                    className={`surf-select__custom-select-container`}
                    style={{
                        pointerEvents: isDisabled ? 'none' : 'auto',
                        opacity: isDisabled ? 0.5 : 1
                    }}
                >
                    <div className={`surf-select__text-wrapper ${isDisabled ? ' disabled' : ''}`}>
                        {t('language.current_code') === 'nl' ? props2.data.labelNL : props2.data.labelEN}
                    </div>
                </div>
            );
        };

        useEffect(() => {
            getVocabulary(props.name, (response) => {
                setOptions(response.data);
            })
        }, []);

        function getOptions() {
            return options.map(option => {
                option.label = t('language.current_code') === 'nl' ? option.labelNL : option.labelEN;
                option.disabled = props.vocabularies.some(vocab => vocab.id === option.id);

                return option;
            });
        }

        function getVocabulary(name, onSuccess) {
            function onValidate(response) {}
            function onLocalFailure(error) {
                Toaster.showDefaultRequestError()
                GlobalPageMethods.setFullScreenLoading(false)
            }

            function onServerFailure(error) {
                Toaster.showServerError(error)
                if (error.response.status === 401) { //We're not logged, thus try to login and go back to the current url
                    props.history.push('/login?redirect=' + window.location.pathname);
                }
                GlobalPageMethods.setFullScreenLoading(false)
            }

            const vocabularyCallConfig = {
                params: {
                    "filter[fieldKey]": name,
                    "filter[ParentOption]": 'null',
                    "filter[isRemoved][EQ]": 0,
                    'page[number]': 1,
                    'page[size]': 10,
                }
            };

            if (!props.retainOrder) {
                vocabularyCallConfig.params.sort = label
            }

            Api.jsonApiGet('metaFieldOptions', onValidate, onSuccess, onLocalFailure, onServerFailure, vocabularyCallConfig);
        }

        function handleAddVocabulary(){
            // if (selectedMethod === 'method-diy'){
                if (selectedVocabulary){
                    props.selectedVocabularyOption(
                        selectedVocabulary
                    )
                }
            // }
        }

        return (
            <Container className={"add-publication-popup-content"}>
                <TopFlexContainer className={"close-button-container"}>
                    <h3>{resolveTitle(props.jsonKey, props.label)}</h3>
                    <FontAwesomeIcon icon={faTimes} style={{cursor: "pointer"}} onClick={props.onCancel}/>
                </TopFlexContainer>
                <Flex style={{marginTop: '20px'}}>
                    <VocabularyOptionField
                        className={"surf-dropdown"}
                        classNamePrefix={"surf-select"}
                        options={getOptions()}
                        isOptionDisabled={(option) => option.disabled}
                        onChange={(selection) => {
                            setSelectedVocabulary(selection)
                        }}
                        components={{
                            SingleValue: CustomSelectElement
                        }}
                        /* Needed extra CSS to align it to the left */
                        formatOptionLabel={option => (
                            <div style={{alignSelf: "start", margin: 0, textAlign: "left", color: `${option.disabled ? 'lightgray' : 'black'}`}}>
                                <span>{option.label}</span>
                            </div>
                        )}
                    />
                    <Tooltip text={t("vocabulary_field.popup.explanation")}/>
                </Flex>
                {/*<SelectMethodElement*/}
                {/*    selectedVocabulary={selectedVocabulary}*/}
                {/*/>*/}

                <Buttons>
                    <SURFButton
                        shape={SURFShapeRight}
                        backgroundColor={spaceCadetLight}
                        highlightColor={spaceCadetLight}
                        text={t("vocabulary_field.actions.cancel")}
                        padding={'0px 32px 0px 32px'}
                        onClick={props.onCancel}
                    />
                    <SURFButton
                        shape={SURFShapeRight}
                        backgroundColor={majorelle}
                        highlightColor={majorelleLight}
                        text={
                            (selectedMethod === 'method-ai') ?
                                t("voca\bulary_field.actions.generate") :
                                props.jsonKey?.includes('vocabulary') ? t('vocabulary_field.actions.add') : (t("language.current_code") === 'nl') ? `${props.label[0] + props.label.slice(1).toLowerCase()} ${t('action.add').toLowerCase()}` : `${t('action.add')} ${props.label.toLowerCase()}`
                        }
                        padding={'0px 32px 0px 32px'}
                        disabled={selectedVocabulary === null || !selectedMethod}
                        onClick={() => {
                            handleAddVocabulary(selectedVocabulary)
                        }}
                    />
                </Buttons>
            </Container>
        )
    }

    // function SelectMethodElement(selectedVocabulary){
    //     const Container = styled.form`
    //         margin-top: 20px;
    //         width: 100%;
    //         height: 100px;
    //         display: flex;
    //         gap: 10px;
    //     `
    //
    //     const VerticalContainer = styled(Container)`
    //         flex-direction: column;
    //     `
    //
    //     const OptionContainer = styled.div`
    //         width: 50%;
    //         height: 100%;
    //         position: relative;
    //         border: #906AF1 2px solid;
    //         border-radius: 20px;
    //         padding: 10px;
    //         display: flex;
    //         flex-direction: column;
    //         cursor: pointer;
    //         gap: 10px;
    //     `
    //
    //     const RadioButton = styled(PrefixRadioButtons)`
    //         position: absolute;
    //         left: 5px;
    //         top: 5px;
    //
    //         input[type='radio'] {
    //             width: ${ props => props.width} !important;
    //             height: ${ props => props.width} !important;
    //             color: ${ props => props.color} !important;
    //         }
    //     `
    //
    //     const Text = styled.p`
    //         font-size: 12px;
    //         line-height: 1.2rem;
    //         margin: 0;
    //     `
    //     const WarningError = styled(Text)`
    //         color: red;
    //         height: 20px;
    //     `
    //
    //     return (
    //         <VerticalContainer>
    //             <Container>
    //                 <OptionContainer
    //                     id="method-ai"
    //                     onClick={() => setSelectedMethod('method-ai')}
    //                 >
    //                     <RadioButton
    //                         width={'15px'}
    //                         height={'15px'}
    //                         color={majorelle}
    //                     >
    //                         <input
    //                             type="radio"
    //                             name="options"
    //                             value={0}
    //                             checked={selectedMethod === 'method-ai'}
    //                             onChange={() => setSelectedMethod('method-ai')}
    //                         />
    //                     </RadioButton>
    //                     <Image src={AIicon} />
    //                     <Text>{t("vocabulary_field.popup.ai")}</Text>
    //                 </OptionContainer>
    //                 <OptionContainer
    //                     id="method-diy"
    //                     onClick={() => setSelectedMethod('method-diy')}
    //                 >
    //                     <RadioButton
    //                         width={'15px'}
    //                         height={'15px'}
    //                         color={majorelle}
    //                     >
    //                         <input
    //                             type="radio"
    //                             name="options"
    //                             value={1}
    //                             checked={selectedMethod === 'method-diy'}
    //                             onChange={() => setSelectedMethod('method-diy')}
    //                         />
    //                     </RadioButton>
    //                     <Image src={OwnInputIcon} />
    //                     <Text>{t("vocabulary_field.popup.diy")}</Text>
    //                 </OptionContainer>
    //             </Container>
    //             <WarningError>
    //                 {selectedMethod === 'method-ai' && t("vocabulary_field.popup.ai_warning")}
    //             </WarningError>
    //         </VerticalContainer>
    //     );
    // }
}

export default VocabularyPopupContent2;