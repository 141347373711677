const datepicker_nl = {
    name: "gregorian_en_lowercase",
    months: [
        ["januari", "jan"],
        ["februari", "feb"],
        ["maart", "mrt"],
        ["april", "apr"],
        ["mei", "mei"],
        ["juni", "jun"],
        ["juli", "jul"],
        ["augustus", "aug"],
        ["september", "sep"],
        ["oktober", "okt"],
        ["november", "nov"],
        ["december", "dec"],
    ],
    weekDays: [
        ["zaterdag", "za"],
        ["zondag", "zo"],
        ["maandag", "ma"],
        ["dinsdag", "di"],
        ["woensdag", "wo"],
        ["donderdag", "don"],
        ["vrijdag", "vr"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
};

export default datepicker_nl;
