import React, {useEffect, useRef, useState} from "react";
import "../../../components/field/datepicker/datepicker.scss";
import {default as Base} from "react-multi-date-picker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import datepicker_nl from "../../../resources/locales/nl/datepicker_nl";
import {useTranslation} from "react-i18next";
import datepicker_en from "../../../resources/locales/en/datepicker_en";


function ReportsDatePicker({
                        name,
                        placeholder,
                        isRequired,
                        defaultValue,
                        onChange,
                        onBlur,
                        ...props
                    }) {
    const { i18n, t } = useTranslation();
    const inputRef = useRef();
    const [pickerValue, setPickerValue] = useState(defaultValue ? new Date(defaultValue) : null);
    const [value, setValue] = useState(defaultValue ? defaultValue : '');
    const [error, setError] = useState('');

    const handleChange = ({ validatedValue }) => {
        if (Array.isArray(validatedValue) === false || validatedValue.length === 0 || validatedValue[0] == null) {
            setPickerValue(null);
            setValue('');
            onChange && onChange(null);
            return;
        }

        const dateParts = validatedValue[0]?.split("-");
        const newDate = new Date(Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0]));

        setPickerValue(newDate);
        const formattedDate = newDate.toISOString().substring(0, 10);
        setValue(formattedDate);

        // Validate if required
        if (isRequired && !formattedDate) {
            setError('This field is required');
        } else {
            setError('');
        }

        onChange && onChange(formattedDate);
    };

    const handleBlur = () => {
        if (isRequired && !value) {
            setError('This field is required');
        }
        onBlur && onBlur(value);
    };

    return (
        <div className={`field-input-wrapper text-field ${error ? 'invalid' : ''}`}>
            <div className="datepicker-wrapper">
                <FontAwesomeIcon icon={faCalendar} />
                <Base
                    ref={inputRef}
                    name={name}
                    shadow={false}
                    format={"DD-MM-YYYY"}
                    placeholder={placeholder ?? t('datepicker.placeholder')}
                    value={pickerValue}
                    onChange={(e, ele) => handleChange(ele)}
                    onBlur={handleBlur}
                    locale={i18n.language === 'nl' ? datepicker_nl : datepicker_en}
                    offsetY={5}
                    highlightToday={false}
                />
                <FontAwesomeIcon
                    icon={faChevronDown}
                    onClick={() => inputRef.current?.querySelector('input')?.focus()}
                />
            </div>
            {error && <div className="error-message">{error}</div>}
            <input
                type="hidden"
                name={name}
                value={value}
                required={isRequired}
            />
        </div>
    );
}
export default ReportsDatePicker